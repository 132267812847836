
.header-logo{
    height: 80%;
    width: initial;
}
.search_icon{
    @include recolor(#e4cd54)
}
.jackpot_wrapper{
    background-color: rgb(30, 6, 0);
}
.bonus_wrapper .bonus_container .bonus_pic {
    height: 42%;
}
.btn.bonus {
    right: 7vw;
    bottom: auto;
    top: auto;
    height: 70%;
}
@media screen and (max-width: 600px) {
    .bonus_wrapper .bonus_container .bonus_pic {
        height: 42%;
        margin-bottom: 25px;
    }
    .btn.bonus {
        right: auto;
        bottom: 30px;
        top: auto;
        height: initial;
    }
    .bonus_wrapper{
        img{
            position: absolute;
            right: initial !important;
            top: 10%;
            height: 25%;
        }
    }
}