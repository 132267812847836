@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Asul:wght@400;700&display=swap');

/* SUPREME CASINO */

$font-family: 'DM Sans', sans-serif;
$font-family-promo: 'Asul', sans-serif;


$accent-color: #e6cf55;
$background-color:#260801;

